import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useHistory, useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Funnel from '../../../../templates/contents/Funnel';
import DataProvider from '../../../../provider';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CodeIcon from '@material-ui/icons/Code';
import { TSodexoResponseSuccess, SodexoResponse } from './types';
import Alert from '@material-ui/lab/Alert';
const useStyles = makeStyles((theme: Theme) => ({
	input: {
		padding: theme.spacing(2, 0, 0),
	},
	button: {
		margin: theme.spacing(2, 0, 0),
	},
	container: {
		padding: theme.spacing(4, 2),
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column'
	},
	text: {
		padding: theme.spacing(2, 0),
	},
	textBody: {
		padding: theme.spacing(0.5, 0),
	},
}));

type SodexoPayStatus = 'FETCH_DATA' | 'FETCHING_DATA' | 'DATA_FETCHED' | 'ERROR_DATA_FETCHING' | 'SUBMIT_DATA' | 'SUBMITTING' | 'VALIDATION_ERROR' | 'SUBMITTING_ERROR' | 'FETCHING_DATA_MAX_ATTEMPT';

export default function SodexoPay (props) {
    const [status, setStatus] = useState<SodexoPayStatus>('FETCH_DATA');
	const [otp, setOtp] = useState<string>('');
	const [info, setInfo] = useState<TSodexoResponseSuccess | null>(null);
	const [feedBack, setFeedBack] = useState<string>('');
	const [submitTextError, setSubmitTextError] = useState<string>('Campo obbligatorio');
    const history = useHistory();
	const classes = useStyles();
	const [retryCount, setRetryCount] = useState<number>(0);
	
	let params = useParams();
	let uuid = params['uuid'];

	function validateOTPLength(otp: string) {
		const otpRegex = /^[0-9]/;
		return otpRegex.test(otp) && otp.length >= 6 && otp.length <= 9;
	}
	
    useEffect(() => {
		let timeout: null | NodeJS.Timeout = null
		if(status === 'FETCH_DATA' && uuid) {
			if(retryCount < 6) {
				setStatus('FETCHING_DATA');
				const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
				dataProvider(`b2c/payment/integration/sodexo/sodexo-ticket/${uuid}`)
					.then((respons: SodexoResponse) => {
						if(respons.success) {
							const { data } = respons
							if(data.errors) {
								setFeedBack(data.errors)
							}
							if (['REFUNDED', 'CANCELLED', 'COMPLETED', 'AWAITING_REFUND'].includes(data.status)) {
								if(!data.redirect) {
									throw new Error();
								}
								if(data.redirect.type === 'internal') {
									history.replace(data.redirect.url);
								} else if(data.redirect.type === 'external') {
									window.location.href = data.redirect.url;
								}
							} else {
								if(data.status === 'AWAITING_COMPLETION') {
									if(timeout) {
										clearTimeout(timeout);
									}
									timeout = setTimeout(() => {
										setRetryCount(retryCount + 1);
										setStatus('FETCH_DATA');
									}, 2000);
								} else {
									setStatus('DATA_FETCHED');
									setInfo(data);
								}
								
							}
							
						} else {
							throw new Error();
						}
						
					})
					.catch(e => {
						setStatus('ERROR_DATA_FETCHING');
						console.log(e)
					})
			} else {
				setStatus('FETCHING_DATA_MAX_ATTEMPT');
			}
		}
		return () => {
			if(timeout) {
				clearTimeout(timeout);
			}
		}
	}, [history, retryCount, status, uuid]);

	useEffect(() => {
		if(status === 'SUBMIT_DATA') {
			if(!otp) {
				setStatus('VALIDATION_ERROR');
				setSubmitTextError('Codice obbligatorio');
				return;
			} else if(!validateOTPLength(otp)) {
				setStatus('VALIDATION_ERROR');
				setSubmitTextError(`Codice non valido.`);
				return;
			}
			setStatus('SUBMITTING');
			setSubmitTextError('');	
			const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
				dataProvider(`b2c/payment/integration/sodexo/sodexo-ticket/${uuid}`, {
					method: 'POST',
					body: JSON.stringify({
					  otp
					})
				})
				.then((respons: SodexoResponse) => {
					if (respons.success) {
						const { data } = respons
						if (data.status === 'COMPLETED') {
							if(!data.redirect) {
								throw new Error();
							}
							if(data.redirect.type === 'internal') {
								history.replace(data.redirect.url);
							} else if(data.redirect.type === 'external') {
								window.location.href = data.redirect.url;
							}
					    } else {
							setStatus('FETCH_DATA')
						}

					} else {
						setStatus('SUBMITTING_ERROR');
						setFeedBack(respons.errorMessage)
					}
				})
				.catch(e => {
					setStatus('SUBMITTING_ERROR');
					setFeedBack('Errore durante il caricamento')
				})
		}
	}, [history, status, otp, uuid]);

    return <Funnel title="Ricarica Pluxee" loading={[
		'FETCH_DATA',
		'FETCHING_DATA',
		'SUBMIT_DATA',
		'SUBMITTING'
	].includes(status)}>
        <Container className={classes.container} component="main" maxWidth="xs"> 
			{status === 'ERROR_DATA_FETCHING' && <>
				<Typography className={classes.text}>Errore durante il caricamento</Typography> 
				<Button size='large' onClick={() => {
					setStatus('FETCH_DATA')
				}} variant="contained" color="primary">
					Riprova
				</Button>
			</>}
			{['SUBMITTING_ERROR', 'DATA_FETCHED'].includes(status) && feedBack && <Alert severity="error">{feedBack}</Alert>}
			{status === 'FETCHING_DATA_MAX_ATTEMPT' && <>
				<Typography className={classes.text}>Conferma in corso...</Typography>
				<Button
					onClick={() => {
						setRetryCount(0);
						setStatus('FETCH_DATA');
					} }
					fullWidth={true}
					variant="contained"
					size="large"
					color="primary"
				>
					RIPROVA
				</Button>
			</>}
			{['VALIDATION_ERROR', 'DATA_FETCHED', 'SUBMITTING_ERROR'].includes(status) && info?.status === 'DRAFT' && <>
			<Typography align='center' className={classes.text}>Istruzioni Pluxee</Typography>
			<Typography variant="body2" component="ol">
				<li className={classes.textBody}>Apri l’App Pluxee e clicca su “Paga ora”.</li>
				<li className={classes.textBody}>Inserisci l’importo del conto e clicca su “Procedi”.</li>
				<li className={classes.textBody}>Scegli quanti buoni vuoi utilizzare con i tasti “+” e “-“ e clicca su “Procedi” (ricorda che il numero massimo di buoni utilizzabili è pari ad 8).</li>
				<li className={classes.textBody}>Clicca su “Procedi” nella finestra di riepilogo.</li>
				<li className={classes.textBody}>Inserisci il codice generato dall’App qui sotto per completare la ricarica.</li>
			</Typography>
			<TextField
				className={classes.text}
				type='tel'
				fullWidth
				value={otp}
				error={status === 'VALIDATION_ERROR'}
				helperText={status === 'VALIDATION_ERROR' ? submitTextError : null}
				variant="outlined"
				placeholder={'Codice Pluxee'}
				onChange={(event) => setOtp(event.target.value)}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<CodeIcon />
						</InputAdornment>
					)
				}}
			/>
			<Button fullWidth className={classes.button} size='large' onClick={() => {
					setStatus('SUBMIT_DATA')
				}} variant="contained" color="primary">
					Conferma
			</Button>
			</>}
			
        </Container>
    </Funnel>;
}
