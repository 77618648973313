import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { useHistory, useParams, useLocation } from "react-router-dom";
import DataProvider from '../../provider';
import { PaymentContext, PaymentContextArray, PaymentInstrument, PaymentOption } from './interfaces';
import Typography from '@material-ui/core/Typography';
import Funnel from '../../templates/contents/Funnel';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { getKey, setKey, CrossStoreType } from '../../cross-store';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Card from '@material-ui/core/Card';
import ButtonBase from '@material-ui/core/ButtonBase';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import CreditCardIcon from '@material-ui/icons/CreditCard';

type PaymentOptionsStatus = 'ERROR_LOCAL' | 'FETCH_DATA' | 'FETCHING_DATA' | 'DATA_FETCHED' | 'ERROR_DATA_FETCHING' | 'SENDING_DATA' | 'ERROR_SEND_DATA';

interface PaymentOptionsResponse {
	paymentInstruments: PaymentInstrument[],
	paymentOptions: PaymentOption[],
}

const titles: {
	definedAmountMethod: string;
	walletRechargeMethod: string;
	partialAmountMethod: string;
} = {
	definedAmountMethod: 'Scegli come pagare',
	walletRechargeMethod: 'Scegli come ricaricare',
	partialAmountMethod: 'Scegli come ricaricare',
};

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		padding: theme.spacing(2)
	},
	card: {
		width: '100%',
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		padding: 16,
	},
	icon: {
		margin: theme.spacing(0, 0, 0, 2),
	},
	full: {
		width: '100%',
	},
	image: {
		width: '80px',
	},
	buttonError: {
		margin: theme.spacing(2, 0),
	},
	labelContainer: {
		maxWidth: '65%',
		width: '100%'
	},
	providerContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flex: 1
	}
}));


export default function PaymentOptions(props: any) {
	const history = useHistory();
	const [status, setStatus] = useState<PaymentOptionsStatus>('FETCH_DATA');
	const [data, setData] = useState<PaymentOptionsResponse>();
	const classes = useStyles();
	const params = useParams();
	const context: PaymentContext = params['context'];
	let location = useLocation();
	const mySearchParams = new URLSearchParams(location.search);
	const return_url = mySearchParams.get('return-url');
	const key_to_update: CrossStoreType = mySearchParams.get('key-to-update') as CrossStoreType;

	useEffect(() => {
		const valuePicked = getKey('PAYMENT_OPTIONS_ADDED_PI');
		if (status === 'FETCH_DATA' && context && valuePicked) {
			setStatus('SENDING_DATA')
			const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
			dataProvider(`b2c/payment-preference/update/${context}`, {
				method: 'POST',
				body: JSON.stringify({
					paymentInstrumentId: valuePicked.id,
					category: valuePicked.category,
					provider: valuePicked.provider,
				}),
			})
			.then((response: {
				error: boolean;
				message: string;
			}) => {
				if(response.error) {
					throw new Error();
				}
				if(history.location.state && history.location.state['key_to_update']) {
					setKey(history.location.state['key_to_update'], valuePicked);
				} else if(key_to_update) {
					setKey(key_to_update, valuePicked);
					if(return_url) {
						history.replace(decodeURIComponent(return_url));
						return;
					}
				}
				history.goBack();
			})
			.catch(e => {
				setStatus('ERROR_SEND_DATA');
			})
		} else if (status === 'FETCH_DATA' && context && !valuePicked) {
			if (!PaymentContextArray.includes(context)) {
				setStatus('ERROR_LOCAL');
				return;
			}
			setStatus('FETCHING_DATA');
			const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
			dataProvider(`b2c/payment/options/${context}`)
				.then((paymentOptionsResponse: PaymentOptionsResponse) => {
					if (!paymentOptionsResponse || !paymentOptionsResponse.paymentInstruments || !paymentOptionsResponse.paymentOptions) {
						throw new Error();
					}
					if(paymentOptionsResponse.paymentInstruments.length === 0 && paymentOptionsResponse.paymentOptions.length === 1 && paymentOptionsResponse.paymentOptions[0].createPIPath) {
						if(mySearchParams.get('paymentOptionsNull') === 'yes') {
							if(history.location.state && history.location.state['key_to_update']) {
								setKey(history.location.state['key_to_update'], valuePicked);
							} else if(key_to_update) {
								setKey(key_to_update, valuePicked);
								if(return_url) {
									history.replace(decodeURIComponent(return_url));
									return;
								}
							}
							history.goBack();
						} else {
							mySearchParams.append('paymentOptionsNull', 'yes');
							history.replace(`${location.pathname}?${mySearchParams.toString()}`, {
								key_to_update: history.location && history.location.state && history.location.state['key_to_update']
							});

							// to cover the case where the path from api has already some query params
							const pathParts = paymentOptionsResponse.paymentOptions[0].createPIPath.split('?');
							const params = new URLSearchParams(pathParts[1]);
							params.set('key-to-update', 'PAYMENT_OPTIONS_ADDED_PI');
							params.set('return-url', encodeURIComponent(window.location.pathname + window.location.search));

							history.push(`${pathParts[0]}?${params.toString()}`, {
								key_to_update: 'PAYMENT_OPTIONS_ADDED_PI'
							})
						}
					} else {
						setData(paymentOptionsResponse);
						setStatus('DATA_FETCHED');
					}
				})
				.catch(e => {
					setStatus('ERROR_DATA_FETCHING');
				})
		}
	}, [context, history, key_to_update, location.pathname, mySearchParams, return_url, status])

	return (
		<Funnel title={{
			'FETCH_DATA': 'Caricamento...',
			'FETCHING_DATA': 'Caricamento...',
			'DATA_FETCHED': titles[context],
			'SENDING_DATA': 'Invio...',
			'ERROR_SEND_DATA':  'Errore invio dati',
		}[status] || 'Errore caricamento dati'} loading={['FETCH_DATA', 'FETCHING_DATA'].includes(status)}>
			<Container className={classes.container} component="main" maxWidth="xs">
				{['ERROR_DATA_FETCHING', 'ERROR_LOCAL'].includes(status) && <>
					<Typography align="center" variant="subtitle1">Ops.. C'è stato un errore durante il caricamento dei dati.</Typography>
					<Button className={classes.buttonError} fullWidth color="primary" variant="contained" size="large" onClick={history.goBack}>INDIETRO</Button>
				</>}
				{['ERROR_SEND_DATA'].includes(status) && <>
					<Typography align="center" variant="subtitle1">Ops.. C'è stato un errore durante l'invio dei dati.</Typography>
					<Button className={classes.buttonError} fullWidth color="primary" variant="contained" size="large" onClick={() => {
						setStatus('FETCH_DATA');
					}}>RIPROVA</Button>
				</>}
				{status === 'DATA_FETCHED' && data && data.paymentInstruments.length === 0 && data.paymentOptions.length  === 0 && <>
					<Typography align="center" variant="subtitle1">Ops.. Non è possibile continuare.</Typography>
					<Button className={classes.buttonError} fullWidth color="primary" variant="contained" size="large" onClick={history.goBack}>INDIETRO</Button>
				</>}
				{status === 'DATA_FETCHED' && data && data.paymentInstruments.length > 0 && new URLSearchParams(window.location.search).get("hide") !== 'payment' ? <List subheader={<ListSubheader disableSticky={true}>
					{{
						definedAmountMethod: 'I tuoi metodi di pagamento',
						partialAmountMethod:'I tuoi metodi di pagamento',
						walletRechargeMethod:'I tuoi metodi di ricarica'
					}[context]}
				</ListSubheader>}>
					{data.paymentInstruments.map(instrument => {
						return <GenericPaymentCard key={instrument.id} data={instrument} action={() => {
							setStatus('SENDING_DATA');
							const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
							dataProvider(`b2c/payment-preference/update/${context}`, {
								method: 'POST',
								body: JSON.stringify({
									paymentInstrumentId: instrument.id,
									category: instrument.category,
									provider: instrument.provider,
								})
							})
							.then((response: {
								error: boolean;
								message: string;
							}) => {
								if(response.error) {
									throw new Error();
								}
								if(history.location.state && history.location.state['key_to_update']) {
									setKey(history.location.state['key_to_update'], instrument);
								} else if(key_to_update) {
									setKey(key_to_update, instrument);
									if(return_url) {
										history.replace(decodeURIComponent(return_url));
										return;
									}
								}
								history.goBack();
							})
							.catch(e => {
								setStatus('ERROR_SEND_DATA');
							})
						}}/>;
					})}
				</List> : null}
				{status === 'DATA_FETCHED' && data && data.paymentOptions.length  > 0 ? <List subheader={<ListSubheader disableSticky={true}>Usa un nuovo metodo</ListSubheader>}>
					{data.paymentOptions.map((paymentOption, key) => {
						return <GenericPaymentCard key={key} data={paymentOption} action={() => {
							if(paymentOption.canCreatePI === 'Yes' && paymentOption.createPIPath) {
								// to cover the case where the path from api has already some query params
								const pathParts = paymentOption.createPIPath.split('?');
								const params = new URLSearchParams(pathParts[1]);
								params.set('key-to-update', 'PAYMENT_OPTIONS_ADDED_PI');
								params.set('return-url', encodeURIComponent(window.location.pathname + window.location.search));

								history.push(`${pathParts[0]}?${params.toString()}`, {
									key_to_update: 'PAYMENT_OPTIONS_ADDED_PI'
								})
							} else {
								setStatus('SENDING_DATA');
								const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
								dataProvider(`b2c/payment-preference/update/${context}`, {
									method: 'POST',
									body: JSON.stringify({
										category: paymentOption.category,
										provider: paymentOption.provider,
									})
								})
								.then((response: {
									error: boolean;
									message: string;
								}) => {
									if(response.error) {
										throw new Error();
									}
									
									if(return_url) {
										history.replace(decodeURIComponent(return_url));
										return;
									}

									history.goBack();
								})
								.catch(e => {
									setStatus('ERROR_SEND_DATA');
								})
							}
						}}/>
					})}
					{context === 'walletRechargeMethod' &&  
					<GenericPaymentCard 
						data={{
							provider: 'day',
							label: 'Day',
						}} 
						action={() => window.open('https://images.foorban.business/day-0d83bcd4-eb02-4710-9e93-37d8169f5c78.pdf', '_blank')}
					/>}
				</List> : null}
			</Container>
		</Funnel>
	);
}

function GenericPaymentCard(props: {
	data: {
		label: string;
		provider?: string;
		sublabel?: string;
	};
	action?: () => void;
}) {
	const classes = useStyles();
	return (
		<ButtonBase className={classes.full} onClick={props.action}>
			<ListItem>
				<Card className={classes.card}>
					<div className={classes.labelContainer}>
						<Typography variant="h6">
							{props.data.label}
						</Typography>
						{props.data.sublabel && <Typography color="textSecondary" variant="body2" noWrap>
							{props.data.sublabel}
						</Typography>}
					</div>
					{props.data.provider && <div className={classes.providerContainer}>
						<img alt={props.data.provider} className={classes.image} src={`https://images.foorban.business/payment/${props.data.provider.toLowerCase()}.svg`}></img>
					</div>}
					{!props.data.provider && <CreditCardIcon fontSize="large" className={classes.image} color="action" />}
					<ArrowForwardIos color="action" className={classes.icon} />
				</Card>
			</ListItem>
		</ButtonBase>
	);
}